import React, { ReactElement } from "react";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TranslationsServiceProvider } from "../../../providers/TranslationsServiceProvider";

const queryClient = new QueryClient();

export type Redirect = (data: { destinationUrl?: string; state: string }, key: string) => void;

export interface RecoveryWrapperProps {
    reactFlow?: boolean;
    redirectService: {
        redirect: Redirect;
    };
    wrapElement: (redirect: Redirect) => ReactElement;
}

const RecoveryWrapper = ({ redirectService, reactFlow, wrapElement }: RecoveryWrapperProps) => {
    if (reactFlow) {
        return wrapElement(redirectService.redirect);
    }

    return (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <TranslationsServiceProvider>
                    {wrapElement(redirectService.redirect)}
                </TranslationsServiceProvider>
            </QueryClientProvider>
        </React.StrictMode>
    );
};

export default RecoveryWrapper;
